import * as dom from "OK/utils/dom";
import { TOOLBAR_DROPDOWN_OPEN, TOOLBAR_DROPDOWN_OPENED } from "OK/ToolbarDropdownEventBuses";
import { IA11yConfiguration } from '@ok/interfaces/a11y/IA11yConfiguration';

import * as a11yConfiguration from 'OK/pms!accessibilityConfiguration';

const { areAccessibleDropDownMenusEnabled } = a11yConfiguration as IA11yConfiguration;

const LINK_CLASS = 'u-menu_a';
const ACTIVE_CLASS = 'toolbar_dropdown_w_hover';

const THEME_CONTAINER_CLASS = 'theme-switcher_container';

class Toolbar {
    private element!: HTMLElement;
    private themeItem!: HTMLElement;
    private closeTimeout?: number;
    private card!: HTMLElement;
    private timeoutHandle: number | null = null;
    private closeBubbleHandle!: string;
    private isBubbleHidden: boolean = false;

    openDropdown = () => {
        if (this.isOpen()) {
            return;
        }

        this.element.classList.add(ACTIVE_CLASS);
        document.addEventListener('click', this.onDocumentClickHandler, false);

        if (areAccessibleDropDownMenusEnabled) {
            this.card.ariaExpanded = 'true';
        }

        TOOLBAR_DROPDOWN_OPENED.emit(null, this);
    }

    closeDropdown = () => {
        window.setTimeout(() => {
            this.element.classList.remove(ACTIVE_CLASS);
            document.removeEventListener('click', this.onDocumentClickHandler, false);

            if (areAccessibleDropDownMenusEnabled) {
                this.card.ariaExpanded = 'false';
            }
        }, this.closeTimeout);
    };

    onDocumentClickHandler = (e: MouseEvent) => {
        // У item'а настроек темы своя обработка, см. ThemeSelector.ts
        if (this.isThemeItemTargeted(e)) {
            return;
        }

        e.preventDefault();

        var element = e.target;
        var tobeHidden = false;

        if (element instanceof Element) {
            if (this.element.contains(element)) {
                if (dom.parent(element, LINK_CLASS, this.element, true)) {
                    tobeHidden = true;
                }
            } else {
                tobeHidden = true;
            }
        }

        if (tobeHidden) {
            this.closeDropdown();
        }
    };

    onCardClick = () => {
        if (this.isOpen()) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
        if (!this.isBubbleHidden) {
            this.isBubbleHidden = true;
            window.navigateOnUrlFromJS(this.closeBubbleHandle);
        }
    };

    mouseEnterHandler = () => {
        window.clearTimeout(this.timeoutHandle!);
    };

    mouseLeaveHandler = () => {
        window.clearTimeout(this.timeoutHandle!);
        this.timeoutHandle = window.setTimeout(this.closeDropdown, 700);
    };

    activate(el: HTMLElement) {
        this.element = el;
        this.closeTimeout = parseInt(this.element.getAttribute('data-timeout')!, 10);
        this.card = dom.firstByClass(this.element, 'js-toolbar-menu')!;
        this.themeItem = el.querySelector(`.${THEME_CONTAINER_CLASS}`)!
        this.timeoutHandle = null;

        this.closeBubbleHandle = this.element.getAttribute('data-hide-dropdown')!;
        this.isBubbleHidden = !this.closeBubbleHandle;

        this.card.addEventListener('click', this.onCardClick, false);
        this.element.addEventListener('mouseenter', this.mouseEnterHandler, false);
        this.element.addEventListener('mouseleave', this.mouseLeaveHandler, false);

        TOOLBAR_DROPDOWN_OPEN.on(this.openDropdown, this);
    }

    deactivate() {
        this.card.removeEventListener('click', this.onCardClick, false);
        document.removeEventListener('click', this.onDocumentClickHandler);
        this.element.removeEventListener('mouseenter', this.mouseEnterHandler, false);
        this.element.removeEventListener('mouseleave', this.mouseLeaveHandler, false);

        TOOLBAR_DROPDOWN_OPEN.off(this.openDropdown);
    }

    private isOpen(): boolean {
        return Boolean(this.element?.classList.contains(ACTIVE_CLASS));
    }

    private isThemeItemTargeted(event: MouseEvent): boolean {
        const targetElement = event.target;

        return targetElement instanceof Element &&
            Boolean(dom.parent(targetElement, THEME_CONTAINER_CLASS, this.themeItem, true));
    }
}

export default Toolbar;
